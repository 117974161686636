// import { NavLink, Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import pro_img from '../images/pro_img.png';
// import download from '../images/download.svg';
// import view from '../images/view.svg';
import Fade from 'react-reveal/Fade';
import { Zoom } from 'react-reveal';

const Info = () => {

    return (
        <div className='container'>
            <Row className='justify-content-between p-2 mt-lg-3'>
                <Col md={4} className='text-center p-2'>
                    {/* IMAGE */}
                    <Col className='p-1 '>
                        <Zoom delay={750}>
                            <img 
                                src={pro_img}  
                                alt='Pia'
                                className='img-fluid ' 
                                style={{  borderRadius: '50%', border: '5px solid var(--trim-primary-color)' }}
                            />
                        </Zoom>
                    </Col>
                    {/* removed access to resume */}
                    {/* <Col className='my-2'>
                    
                        <div className='mx-1 my-0'>
                            <Fade bottom delay={800}>
                                <p className='text my-1'>Resume/CV: </p>
                            </Fade>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div className='mx-1'>
                                <Fade bottom delay={800}>
                                    <Link className='btn btn-outline-primary p-1 resBtn' to='/resume'>
                                        <img src={view} alt='view resume' className='resImg'/>
                                    </Link>
                                </Fade>
                            </div >
                            <div className='mx-1'>
                                <Fade bottom delay={800}>
                                    <button className='btn btn-outline-primary p-1 resBtn'>
                                        <a href='resume.pdf' download='resume.pdf' alt='download-resume'>
                                            <img src={download} alt='download-resume' className='resImg'/>
                                        </a>
                                    </button>
                                </Fade>
                            </div>
                        </div>
                    </Col> */}
                </Col>

                {/* ABOUT  */}
                <Col md={8} className='mt-md-0 px-0 p-md-2'>
                    <div className='m-2' >
                        <Fade bottom delay={800}>
                            <h2 className='about-intro'>A curiously-creative Software Engineer.</h2>
                        </Fade>
                        <Fade bottom delay={800}>
                            <p className='mb-2 text'>
                                I am a Software Engineer that's passionate about solving problems in a human-centric way. I combine my loves for art, science, technology and mindfulness meditation to create beautiful, useful solutions and write clean accessible code. 
                                I really sweat the small stuff because it matters to be aware of people and their needs.
                            </p> 
                        </Fade>
                        <Fade bottom delay={800}>
                            <p className='mb-2 text'>
                                My journey into software engineering has been rewarding and has afforded me the 
                                opportunity to learn various technologies and languages.
                                Currently, I am focused on building innovative, accessible solutions at 
                                <a href='https://www.pointhealth.ai' target='blank' className='special-text touch-link ph-link text-decoration-none'><strong> PointHealth AI</strong></a>.
                            </p> 
                        </Fade>
                        <Fade bottom delay={800}>
                            <p className='mb-2 text'>
                                Being creative, learning, engaging and thinking outside the box to solve problems really makes me happy. 
                                If you would like to connect or just say, "Hi", feel free to contact me!  
                                {/* If you think that I can help solve a problem, or, you just want to say, 'Hi', feel free to contact me!   */}
                            </p> 
                        </Fade>
                        <Fade bottom delay={800}>
                            <NavLink tag='link' className='nav-link touch-link mx-0 p-0'  to='/contact'> 
                                <u><em><h3>Get in Touch!</h3></em></u>
                            </NavLink>
                        </Fade>
                    </div>
                </Col>
            </Row>
            <hr  className='mx-auto my-4 text-primary col-8'/>
        </div>
    )
}

export default Info;